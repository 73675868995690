

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital@1&display=swap');

* {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus{outline: none;}
.help-error{
  margin: 10px;
    color: yellow;
    font-size: 13px;
}
.help-error2{
  margin: 10px;
    color: red;
    font-size: 13px;
}
.block {
  display: block;
}

.defaulpad {
  padding: 80px 0px;
}

body {
  font-size: 16px;
  background-color: #fff;
   font-family: 'Roboto', sans-serif;
  color:#000000;
}
/* Change the white to any color */
/* input.atf:-webkit-autofill,
input.atf:-webkit-autofill:hover, 
input.atf:-webkit-autofill:focus, 
input.atf:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
} */
/*Additionally, you can use this to change the text color:*/

/*Change text in autofill textbox*/
input.atf:-webkit-autofill{
    -webkit-text-fill-color: #000 !important;
}
a.dbg{color: #fff;}
a.dbg:hover{color: #cdff00;}

a.textlink{color: #0a6f43;}
a.textlink:hover{color: #69b443;}

p, .list li{
  font-size: 16px;
  line-height: 25px;
   font-family: 'Roboto', sans-serif;
   font-weight:normal;
   
}
p b{font-weight: bold;}

.pdr{padding-right: 22px;}
.pdl{padding-left: 22px;}

.mtop20 {
  margin-top: 20px;
}
.mtop15{margin-top: 15px;}

.paddinTB40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}
.textlink{ color: #0a6f43;}
.textlink:hover{color: #08b162;}
input:hover, textarea:hover, textarea:focus,
input:focus {
  stroke: 0;
  outline: 0;
  border: 0;
}

input[type=submit],
a {
  transition: all 300ms ease-in;
}
.mobimg{ display: none !important;}
.mobreqest .quotes{ text-transform: uppercase;}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;

  box-shadow: 0 0 0px 1000px rgba(41, 36, 36, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
}


.drkform input:-webkit-autofill,
.drkform input:-webkit-autofill:hover,
.drkform input:-webkit-autofill:focus,
.drkform textarea:-webkit-autofill,
.drkform textarea:-webkit-autofill:hover,
.drkform textarea:-webkit-autofill:focus,
.drkform select:-webkit-autofill,
.drkform select:-webkit-autofill:hover,
.drkform select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
}
.trnsintion {
  transition: all 400ms ease-in-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h3 {
   font-family: 'Roboto', sans-serif;
  font-weight:bold;
  color: #000;
}
h2{padding-bottom: 10px; padding-top: 10px;}

h2:nth-child(1){padding-top: 0px;}

h3 {
  font-size: 19px;
  font-weight: 900;
}

h5{font-size: 18px; font-weight: 700; padding: 10px 0px;}

h1 {
  font-size: 24px;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.link {
  min-width: 165px;
  text-align: center;
  padding:23px 40px;
  color: #fff;
  font-size: 15px;
  background: #0a6f43;
border-radius:30px;
  display: inline-block;
   font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  font-weight: bold;


}

.link:hover{ background: #ffff !important; color: #0a6f43; box-shadow: 0px 0px 20px #0a6f432f;}

.header {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0;
  background-color: #fff;
  padding: 5px 0px;
  z-index: 100;
  transition: all 400ms ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.nbar{padding-top: 10px !important; padding-bottom: 10px !important}

.header.active .topbar{
max-height: 0px;
padding: 0; overflow: hidden;
}



.header .container {
  display: flex;
  align-items: center;
}

.menubutton {
height: 27px;
  z-index: 4;
  position: relative;
  background:transparent;
  text-align: center;
border: 0px;
vertical-align: middle;
display: none;
}

.menubutton span {
  display: block;
  width: 21px;
  height: 2px;
  position: relative;
  transition: all .3s ease-in-out;
  background-color: rgb(0, 0, 0);
  margin: 5px auto;
}

.menubutton.opened span:first-child {
  transform: rotate(45deg);}

  .menubutton.opened span:nth-child(3) {
    transform: rotate(-45deg);
}
.menubutton.opened span {
  margin: -2px auto auto;
  background-color: #fff;
}

.menubutton.opened span:nth-child(2) {
  visibility: hidden;
  height: 0;
}


.brand img {
  height: 64px;
  vertical-align: middle;
}


.navebar,
.nav{
  display: flex;
  list-style: none;
  align-items: center;
}
.nav li {
  padding: 0px 8px;

  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: default;
  transition: all 300ms ease-in;
}


.otherNav li{border-radius: 5px;
  background: #f3f3f3; padding: 10px 15px; margin-right: 15px;}

.nav li:hover, .nav li a:hover, .nav a.activelink{ color: #0A6F43 ;}

.nav li:nth-child(1) {
  padding-left: 0px;
}
.nav a {
  display: block;
  color: #000;
}
.nav i{vertical-align: middle;}

.navebar a.selected,
.navebar a:hover {
  opacity: 1;
}

/* submen */

.submenu{  overflow: visible; } 
.submenu:hover{overflow: visible; }
.megamenu {
  transition: all 300ms ease-in-out;
  margin-top: 3vh;
  position:absolute;
visibility:hidden;
width:250px;
left: -75px;

padding-top: 32px;
opacity: 0;
}
.color{animation:colordot 5s linear infinite; transition-delay: 2.5s; }


.submenu:hover .megamenu{margin-top: 0vh; visibility:visible; opacity: 1;}
.backdrop{ position: relative;
  
  border-top: 1px solid #ececec;
  padding: 20px 0px; background: transparent;
 
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
   }
   .backdrop::after{ 	border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ececec; position: absolute;
  left: 50%; margin-left: -15px; top: -11px; content: '';
  }
 

.megamenu a, .othertab{ line-height: 1.5;  color: #000 !important;  display: block;
   padding: 10px 30px; border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    border-top:1px solid rgba(255, 255, 255, 0.3);  }
    .megamenu a:nth-child(1){border-top: 0px;}
    .megamenu a:nth-last-child(1){border-bottom: 0px;}

    .tab{color: #6D6C6C !important;}

.megamenu h3{ margin-bottom: 0px; margin-top: 5px;  color: #0000; font-size: 16px; 
   padding: 5px 0px; padding-bottom: 0px;} 
   .megamenu h3 a{color: #EF008B; margin-left: 0px; padding-left: 0px; border-left: 0px;}
.megamenu a:hover, .tab:hover, .megamenu a.activelink{ background-color: #0a6f43; color: #fff !important;}


/* banner */

.banner{position: relative;}

.bannerimg{
  height: calc(100vh - 105px);
  object-fit: cover;
  min-height: 545px;
  object-position: bottom right;
}
.bannerimg2{
  height: calc(100vh - 105px);
  object-fit: cover;
  min-height: 545px;
  object-position: center left;
}
  .bannercon{
    position: absolute;
    left: 0;
    top:0;
    padding-top: 75px;
    padding-bottom: 25px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .BannerTwo .bannercon{padding-top: 100px;}
  
  .bannercon p{color: #fff;}
  .bannercon h1{color: #fff; font-size:3vw; margin-bottom: 20px;}
  .control-dots{display: none;}
  .bannercon .link{margin: 13px 0px 13px 0;}
  .BannerOne .bannercon{background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0.35) 22%, rgba(0,0,0,0.1) 84%);}

    .BannerTwo .bannercon{background: rgba(0,0,0,0.31);}

    .BannerOne h1{ font-size: 24px; color: #fff;  margin-bottom: 0px;}
    .BannerOne h2{ font-size: 20.5px; color: #f9f111;}
    .BannerOne p{font-size: 14px; line-height: 20px;}
    .BannerOne p b{font-weight: 700;}
    .bntags p{ font-size:12px; line-height: 18px; font-style: italic; }

    .BannerTwo .bannercon h1,  .BannerTwo .bannercon h2,  .BannerTwo .bannercon h3,  .BannerTwo .bannercon div {text-align: center;}
    .BannerTwo .bannercon h1{font-size: 24px; margin-bottom: 10px;  margin-bottom:15px; }
    .BannerTwo .bannercon h2{font-size: 20px; color: #043d55;  margin-bottom:15px;}
    .BannerTwo .bannercon div a{margin-bottom:8%;}
    .BannerTwo .bannercon h3{color: #fff; font-size: 22px; margin-bottom:1%;}
    .BannerTwo .bannercon p{font-size: 16px; font-style: italic; color: #f9f111; }
    .three .bannercon div a{ margin-bottom: 0px;}
    .three .bannercon h1{font-size: 23px; line-height: 1.3;}
    .three .bannercon h3{color: #fff; font-size: 18px; margin-bottom:8%;}
   
  .carousel.carousel-slider .control-arrow {
    width: 51px;
    height: 51px;
    background: #eef1e6 !important;
    border-radius: 100%;
    opacity: 1 !important;
    top: unset !important;
    bottom: 50px !important;
    
    text-align: center;
    list-style: 51px;
    font-family: 'ews' !important;
    color: #1b5d33 !important;
    font-size: 25px;
    transition: all 500ms ease-in;
}

.carousel.carousel-slider .control-arrow:hover{  background: #1b5d33 !important; color: #eef1e6 !important;}

.carousel.carousel-slider .control-arrow.control-disabled{ display: block !important; 
  background-color: rgba(255, 255, 255, 0.2)!important; opacity: 1 !important; color: #ffffff52 !important;}

.carousel .control-next.control-arrow{ right:80px !important;}
.carousel .control-prev.control-arrow{ left: unset !important; right: 150px;}
.carousel .control-next.control-arrow:before {
  content: "\e904" !important;
  border:none !important;
}
.carousel .control-prev.control-arrow:before {
  content: "\e902" !important;
  border:none !important ;
}

/* .pagebanner */

.pagebanner{ padding-top: 95px;  background-color: #0a6f43 ; }
.pagebanner h1{color: #f0f0f0;}
.pagebanner .container{ padding: 30px 0px; background-image: url('./img/leaf.svg'); background-repeat: no-repeat;
   background-position:bottom right; background-size:auto 120%;}

   .qoute.mypick2 .quotes{font-size: 19px; text-align-last:justify !important;}

   .column{
overflow: hidden;
  border-radius: 15px;
  background: #ececec;
  margin: 20px 0px;
   }
   .column div{border-bottom: 1px solid rgba(0, 0, 0, 0.185); }
   .column div:nth-child(odd){border-right: 1px solid rgba(0, 0, 0, 0.185); }
   .column div:nth-child(1),  .column div:nth-child(2){ background-color: #0a6f43;}
   .column div:nth-last-child(1),   .column div:nth-last-child(2){border-bottom: 0px;}

   .column p{  padding: 15px 10px !important; display: block; height: 100%;}
   .column p b{color: #fff;}

/* presets */
/* sections */
section, footer{padding: 65px 0px;}
section.nonepd{padding: 0px 0px;}
section img{border-radius: 10px;}
section.qoute{ padding: 50px 0px; background-color: #fff;}
section.conLight, footer{  background: #fff;}
section.conGreenish{ background: #eef1e6;}
section.conLight h2, section.conLight p, section.conGreenish p, section.conGreenish h2{color: #000;}
section.conDark{ background: #000000;}
section.conDark h2, section.conDark p, .white-text p, .white-text h2, .white-text, .conDark .list{
  color: #fff;
  }
  /* speciclHedeng */

  .speciclHedeng{background-color: #fff; padding: 40px;}
  .speciclHedeng h2 {
    max-width: 607px;
    margin: auto;
    line-height: 1.5;
}
/* quotes */
.quotes{
  font-family: Merriweather;
font-weight: normal;
font-size: 20px;
margin:10px 0px;
line-height: 30px;
padding:0px 50px;
color: #0a6f43;
position: relative;
text-align: justify;
text-align-last: justify;
}

.qlefts{text-align-last: left !important; font-weight: normal; line-height: 30px;}

.quotes span{color: #000000;}

.quotes::before, .quotes::after{ font-family: 'ews' !important; 
  font-size: 35px; vertical-align: bottom; position: absolute; }
.quotes::before{content: "\e977";
  left: 0; top:-4px;
}
  .quotes::after{content: "\e978";  right: 0; bottom:-4px; }

  /* tags */

  .tags span{ display: inline-block; margin: 3px; margin-right: 5px; margin-left: 0; padding: 2px 0px; padding-right: 5px; border-right: 3px solid #0a6f43;}
  .tags span:nth-last-child(1){border: 0px; margin-right: 0; padding-right: 0px;}
  /* MypickTwo */
 .mypick2 img{
  min-width: 100%;
  max-width:130px ;
 }
 section.mypick2{backface-visibility: hidden;
  padding-top: 0;
  height: 120px;
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-bottom: 30px;}
  .mypick2 .quotes{ margin-bottom: 28px;}
 
 .list{padding:10px 15px; padding-left: 20px; }



 .list li::marker{ font-size: 24px; color: #0a6f43;}
 
 .list.blackPointer li:nth-child(1){color:#0a6f43;}

 .ol li{padding: 5px 0px !important;}
 .list li{padding: 2px 0px; line-height: 20px; position: relative;  }

 .list li::before,  .list li::after{ position: absolute; content:'';left: -21.5px;
  top: 8.6px;
  border: 1px solid #0a6f43; 
  height: 9px;
  width: 9px; border-radius: 100%; animation:crcl 2s linear infinite ; }
  .list li::after{animation-delay: 1s;}

  .ol.list li::before,  .ol.list li::after{left: -21.5px;
    top: 11.7px;}


    .reab li{line-height: 25px; text-align: justify;}
    .reab li::after, .reab li::before{ display: none; }
    .reab.list li::marker{ font-size: 16px; color: #0a6f43; }

    .br100{ border-radius: 100%;}

    .ablist li::marker{font-weight: 700; }
    .ablist li{padding: 10px 0px; padding-left:5px;}
    .ablist li:nth-child(1){padding-top: 0px;}

    .ablist{ list-style-type: upper-alpha;}

 @keyframes crcl{ 
  0%{ transform: scale(0.0); opacity:1; }

   100%{ transform: scale(2.5); opacity: 0; }
 }


 /* ready */
 .minusmargin{margin-left: -20px;}
 .gbi p{ color: #0a6f43  !important; 
  font-weight: 700 ; font-style: italic;}

 .ready{position: relative; z-index: 2;}
 .wtBg{ position: relative; position: relative; padding:50px 40px;  }
 h2{position: relative; z-index: 2;}
 .wtBg::before{ position: absolute; width: 80%;
   background-color: #fff; border-radius: 10px; content: ''; height: 100%; left: 0; top: 0;}

div.thumbs-wrapper{  margin:0px !important;}

.ready p{font-size: 18px; padding: 15px;}
.ready .link{margin-top: 20px;}


/* mypick3 */

.mypick3 .quotes{text-align-last: left;}

/* ways */
.ways p{padding: 10px 0px;}
.ways p:nth-last-child(1){padding-bottom: 0px;}


/* mypick4 */
.mypick4 p{ padding:10px 0px; text-align: justify;}

.mypick4 .quotes {
  max-width: 725px;
  margin-left: auto;
  margin-right: auto;
}

.mypick4 .link{margin-top: 10px;}

/* coach */
.coachingProgramBP{display: flex; flex-direction: column; justify-content: space-around;}
.coachingProgramBP p{font-size: 20px; }

.coach img{ width: 100%;}
.coach .link{
 font-weight: normal;
 font-size: 20px;
 border-radius: 10px;
font-weight: 700;
 height: 100px;
 margin: 15px 0px;
 padding: 15px 20px;
 text-align: left;
 display: flex;
align-items: center;
}
.coach .link i{
  font-size: 40px; display: flex;
  align-items: center; justify-content: center;
  min-width: 70px;
height:70px;
background: rgba(238, 241, 230, 0.37);
border-radius: 100%;
margin-right: 15px;
}
.coach .link:hover i{
  background: rgba(10, 111, 67, 0.09);
}


/* Socrates */
.socrates .quotes{max-width:800px; width: fit-content; margin: auto;}
.qoute.purquopte.me .quotes{ text-align-last: center;}
/* values */
 .values i{color: #fff;}
.values p, .values .flex{padding: 10px 0px;}

.values p{text-align:justify;}
.progress, .extarqoute p{font-style: italic; color: #cdff00 !important;}
.progress i{ color: #cdff00;     vertical-align: middle; font-size: 40px;   padding: 0px 5px; }

.cicon{
min-width: 42px; height: 42px; border-radius: 100%;
background: #eef1e6; color: #485D4E !important; font-size: 35px; display: flex;
justify-content: center; align-items: center;
margin-right: 15px;
}


/* collaboration */

.collaboration p{text-align: justify; padding: 10px 0px;}
.collaboration p:nth-last-child(1){padding-bottom: 0px;}
.collaboration p:nth-child(1){padding-top: 0px;}
.collaboration .link{margin-top: 10px;}

.WorkWith .ready p {
  font-size: 16px;
  padding: unset;
  padding-top: 10px;
}

/* Purpose */
.mypick2 .quotes{text-align-last: center; font-size: 19px;}
.purquote2 .quotes{width: fit-content; margin: auto;}

.nonepd p{ text-align: justify; padding: 10px 0px;}
.nonepd p:nth-child(1){padding-top: 0px;}
.nonepd p:nth-last-child(1){padding-bottom:20px;}

/* committed */
.committed p{ padding: 5px 0px; padding-left: 5px;}
.committed a{margin-top:15px;}
.committed img{max-width: 100%;}

.f24{ font-size: 24px;}
/* mypickSeve */  
.mypickSeven{background-color: #f1f2ef !important;}

.mypickSeven .quotes, .WhyMe .quotes{ text-align-last: left;}

/* WhyMe */

.WhyMe p{ padding: 10px 0px; text-align: justify;}
.WhyMe img{margin: 10px 0px; display: block;}
.WhyMe .progress, .WhyMe .progress i{color: #0a6f43 !important; padding-top: 0px !important; padding-bottom: 0px !important;}

.rowimg img{width: 100%; height: 250px; object-fit: cover;}

.WhyMe .link:nth-child(1){margin-bottom: 20px; margin-top: 15px;}

.WhyMe.how .link:nth-child(1){margin-bottom:unset; margin-top: 15px;}

/* CHECKOUT */
.checkout a{ display: block; width: fit-content; margin: 10px;}
.checkout img{width: 100%;}



/* watermark */
.watermark{background-size: cover; background-position:center; background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.start-in{ background-image: url('./img/start-investing.jpg'); }
.coachin-plans{background-image: url('./img/coaching-Plan.jpg'); }
.reconnected{background-image: linear-gradient(45deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%), url('./img/reconnected.jpg'); background-position: center bottom;}

.contact{background-image: linear-gradient(45deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%), url('./img/contact.jpg'); background-position: center bottom;}

.what{background-image: linear-gradient(45deg, rgba(255,255,255,0.75) 0%, rgba(255,255,255,0.75) 100%), url('./img/group-coaching-1.jpg');}
.why{background-image: linear-gradient(45deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 100%),  url('./img/why.jpg');}
.how{background-image: linear-gradient(45deg, rgba(255,255,255,0.75) 0%, rgba(255,255,255,0.75) 100%), url('./img/how.jpg');}

.focus{background-image: linear-gradient(45deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%), url('./img/focus.jpg'); background-position: center bottom;}

.grow{background-image: linear-gradient(45deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 100%), url('./img/grow.jpg'); background-position: center right;}
.grow h2{line-height: 2; letter-spacing: 2px;}

.focus h2.mainH{ text-align: center; margin-bottom: 15px;}

.extarqoute{
 
  background-image: 
  linear-gradient(45deg, rgba(0,0,0,0.7) 0%, 
  rgba(0,0,0,0.7) 100%), url('./img/extarqoute.jpg');
}

.youare{ background-image: 
  linear-gradient(45deg, rgba(255,255,255,0.7) 0%, 
  rgba(255,255,255,0.7) 100%), url('./img/yogas.jpg');  }

  .youare img{ border-radius: 100%;}

  /* about us */
 .welcome h2{ padding-bottom: 5px;}
 .hline h2{line-height: 1.4 !important;}
 .impline{margin-bottom: 10px; color: #fff; display: block;}

 .abtcoaching { display: flex; flex-direction: column; justify-content: space-around;    backdrop-filter: blur(10px);
 background-color:rgba(0, 0, 0, 0.1); padding:30px; border-radius: 15px; height: 100%; border: 1px solid rgba(255, 255, 255, 0.1);}

 .abtcoaching p{ text-align: justify; margin-bottom: 15px;}
 .abtcoaching a h2:hover{color: #cdff00;}
 .abtcoaching .link{width: fit-content;}

/* You page */

.cover-image img{ width: 100%; height: 325px; object-fit: cover; object-position: center bottom;}

.f18{font-size: 20px; margin-bottom: 15px;}

/* plans */
.plans{height: 100%; border-radius: 10px; border: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(8px);     display: flex;
  flex-direction: column;
  justify-content: space-between;
  backdrop-filter: blur(8px); background-color: rgba(255, 255, 255, 0.4);
  transition: all 400ms ease-out;
}
.plans:hover{transform: scale(1.05);}
.plans h4{ font-size:17px; line-height: 1.4;     width: 100%; }
.plans p, .plans li{ font-size: 14px; line-height: 1.5;}
.plans p{margin:10px 0px; padding: 0px;}
.plans ul{ padding-left: 20px;}
.plans ul li::marker{color: #69b443; font-size: 20px; color: #0a6f43;}
.hasTag p{ font-size: 11px; margin-top: 10px; line-height: 1.3;}
.coachin-plans p a{font-weight: bold; color: #0a6f43;}

.padding-20{ padding: 20px; }
.areyouready{    width: 100%;
  max-width: 760px;
  max-height: 253px;
  object-fit: cover;
  object-position: center bottom;}

.plans .link {
  width: 100%;
  padding: 20px 20px;
  font-size: 14px;
  border-radius: 0px 0px 10px 10px;
  letter-spacing: 0px;
  line-height: 1.3;
}

.plans span {
  position: absolute;
  height: 37px;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 20px 0px 0px 20px;
  width: 109px;
  background-color: #e7c93b;
  display: flex;
  right: 0px;
}
.planROw{padding: 15px 0px;}
.aftr{ position: relative;}
.aftr .plans h4{display: inline-flex; align-items: center; border-bottom: 1px solid rgba(0, 0, 0, 0.05); padding-bottom: 15px;}
.aftr .plans h4::before{ height:40px; color: #fff; font-weight: 700; margin-right: 8px; font-size: 16px; min-width: 40px; border-radius: 100%; background:#0a6f43;
  display: inline-flex; left:20px; top:-25px; justify-content:center ; align-items: center; }

.aftr:nth-child(1) .plans h4::before{content: '1';  }
.aftr:nth-child(2) .plans h4::before{content: '2';  }
.aftr:nth-child(3) .plans h4::before{content: '3';  }
.aftr:nth-child(4) .plans h4::before{content: '4';  }

.centeQuote{ margin: auto;
  width: fit-content; 
  text-align: center;
  text-align-last: center !important; margin:30px auto;}

  .meShubham .quotes{ text-align-last: center;}

  /* contact */
  .drkform{padding: 15px 20px; margin-bottom:10px;
     backdrop-filter: blur(10px);  transition: all 300ms ease-in;    border: 1px solid rgba(255, 255, 255, 0.1); border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);}
  .drkform label{padding-right: 10px; font-size: 16px;
    margin-right: 10px;
    border-right: 1px solid rgba(255,255,255,0.3);}

    .drkform input, .drkform textarea{ background-color: transparent; font-family: 'Roboto', sans-serif; color: #fff; font-size: 16px; display: block; width: 100%;}

    .drkform:focus-within{  background-color: #0a6f43; }

    ::placeholder { 
      color: rgb(138, 138, 138);
      opacity: 1; 
    }
    
   :-ms-input-placeholder { 
    color: rgb(138, 138, 138);
    }
    
  ::-ms-input-placeholder { 
    color: rgb(138, 138, 138);
    }
    .contactimg img{max-width: 250px; border-radius: 100%;}

    .lgtform{ border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 10px;
      background-color: #fff;}
      .lgtform:focus-within label{ color: #fff; }

      .lgtform label{border-right-color:rgba(0, 0, 0, 0.3) ;}

      .lgtform input{color: #000;}
      .lgtform input:focus{color: #fff;}
      .form{margin-top: 15px;}

      .radio{padding: 15px 0px; margin-bottom:10px;}
      .radio div{margin-right: 20px;}
      .radio input{margin-right: 8px;}

      /* learn */
      .card{
        border-radius: 10px; 
        height: 100%;
      box-shadow: 2px 2px 10px rgb(200 212 168 / 22%);
      overflow: hidden;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.4);
      }
      .card img{width: 100%; border-radius: 0px; }
      .card h2{ font-size: 18px; padding: 10px 20px; padding-bottom: 5px; line-height: 1.5; text-overflow: ellipsis; white-space: nowrap; overflow:hidden; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
      .card p{padding: 5px 20px;  text-align: justify; text-align-last: center;}
      .card div{padding: 10px 0px; padding-bottom: 20px;}
      .card .link{padding: 17px 40px;}

      /* testimonials */

            /* Reviews */

            .greviews{ background-color: rgba(255, 255, 255, 1); border-radius: 15px; padding:40px; height: 100%;
              box-shadow: 5px 5px 20px #8bc34a11;
              position: relative;
            }
           
      
            .greviews::after{ position: absolute; height: 30px; width:30px; content: '\e977'; 
              border-radius: 0px 5px 5px 0px; font-size: 18px; font-family: 'ews' !important;
             background-color: #0a6f43; left: 0; top: 40px; color: #fff; line-height: 30px; text-align: center;}
      
            .greviews p{ position: relative;
              font-size: 14px;
              line-height: 1.7;
              text-align: left; text-align: justify;
              color: #6d6c6c;
              margin-bottom: 20px;
              font-style: italic;
              }
      /* 
              .greviews p::after, .greviews p::before{position: absolute;
                font-size: 50px; opacity: 0.5; 
                content: '"';}
      
                .greviews p::after{
          left: -10px;
                  top: -20px;
               } */
      
               .carousel .slider-wrapper.axis-horizontal .slider .slide {
            
                display: flex !important;
                justify-content: center !important;
            }
      
              .greviews h2{font-size:18px; margin-bottom:0px; padding-bottom: 5px; }
              .greviews span{font-size: 14px; color: #0a6f43; }
              .usericon i{ display: inline-flex; align-items: center;
              justify-content: center; height: 50px; width: 50px; border-radius: 100%; overflow: hidden; background-color: #0a6f43; color: #fff; font-size: 27px; margin-right: 10px;}
              .star{font-size: 24px; color: #ffb600;}
            

              /* .row div:nth-child(3n+2) .greviews{ background-color: rgba(255, 255, 255, 0.808); }

              .row div:nth-child(3n+0) .greviews{ background-color: #ffffff9c;}  */

           

/* footer */
footer img{ max-width: 100%;}
.social{ border-radius: 100%; height: 41px; color: #fff; width: 41px; margin: 10px 5px; display: inline-block; text-align: center; 
  line-height: 45px;
  font-size: 27px;}
  .social:hover{
    transform: scale(1.2);
  }
.social:nth-child(1){ margin-left: 0; background-color: #3b5998;}
.social:nth-child(2){background: rgba(76,95,215,1);
  background: linear-gradient(45deg, rgba(244,209,48,1) 0%, rgba(244,111,48,1) 20%, rgba(195,42,163,1) 54%, rgba(76,95,215,1) 100%);}
  .social:nth-child(3){ margin-right: 0; background: #0a66c2;}
footer p{margin: 10px 0px;}


/* screen 1200 */

@media only screen and (max-width:1200px) {
  .watermark{background-attachment: unset; }

  .quotes{font-size: 18px; text-align-last: center !important;}
  .mypick2 .quotes{margin-bottom: 0px;}
  .brand img{ height: 50px;}
  .nav li {
    padding: 0px 5px;
    font-size: 14px;}
    .qoute.mypick2 .quotes {
  
      text-align-last: center !important;
  }
  .meShubham .flex br:nth-child(1), .meShubham .flex br:nth-child(2), .WhyMe .centeQuote br:nth-child(1){
    content: "";
    display: block;
    margin: -1em 0px; }
}

/* screen 992 */

@media only screen and (max-width:992px){ 
.checkout .col-6 h2{ margin-top: 20px; padding-left: 20px;}
footer .flex{flex-wrap: wrap;} 

  .quotes{font-size: 14px; text-align-last: center !important;}

  .menubutton {
    display: block;
    margin-right: 10px;
}
.flex.aic.mypick4{align-items:flex-start;}
.flex.aic.mypick4 img{ min-width:250px;}
.WorkWith .flex img{ min-width: 250px;}
.link {
  padding: 23px 25px;
  line-height: 1.3;
  font-size: 14px;
  letter-spacing:unset;

}
  
  .nav{position: fixed;
  transition: all 400ms ease-in;
  height: 100%;
  display: block;
  z-index: 1;
  width:310px;
  right: -320px;
  padding-top: 65px;
  padding-left: 20px;
  padding-right: 20px;
  top: 0;
  background: transparent;
  backdrop-filter: blur(12px);
  --webkit-backdrop-filter: blur(12px);
  background-color: #0a6f43e3;
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.2);     
}
.nav.opened{right: 0px; }

.nav li a, .nav li span{padding-left: 10px !important; display: block; color: #fff;}
.nav li a:hover{color: #f9f111;}
  .navebar li a,  .navebar li {
    letter-spacing: inherit;
    font-size: 14px;
    padding: 0px 0px;
    padding: 4px 0px;
    color: #fff;
}

.megamenu {
  transition: all 300ms ease-in-out;
  margin-top: 0px;
  position: unset;
  visibility: visible;
  width: 260px;
  padding-top: 5px;
  padding-left: 0px;
  opacity: 1;
}
.megamenu a{border: 0px; color: #fff !important;
 border-left: 1px solid rgba(255, 255, 255, 0.3); padding-left: 7px !important; line-height: 1.8;}
 .megamenu a:hover{color: #f9f111 !important;}

 .nav a.activelink, .megamenu a.activelink{color: #f9f111 !important; }

 .backdrop::after{display: none;}
.backdrop {
  padding: 0px 0px;
  background: transparent;
  border: 0px;
  -webkit-backdrop-filter: unset;
  backdrop-filter: unset;
  --webkit-backdrop-filter: unset;
  box-shadow: unset;
  border-radius: 0px;
  background-color: transparent;
}
.flex.ready{ flex-wrap: wrap;}
.wtBg::before{width: 100%;}
.flex.ready img{width: 100%;}
.flex.ready img:nth-last-child(1){margin-top: 15px;}
h2 {
  font-size: 1.2rem;
}
.ready p {
  font-size:16px;
}
.committed .flex{ flex-wrap: wrap-reverse; }
.flex img{margin: 15px 0px; max-width: 100%; min-width: 250px;}
.mypick2 img{ min-width: unset;}

.coach .link {
  font-size: 16px;
  line-height: 1.3;
  height: 94px;
  margin: 12px 0px;
  padding: 15px 20px;
}
.greviews i img{max-width: 50px; min-width: unset;}
.greviews h2{font-size: 16px;}
.greviews span{font-size: 13px;}
}

@media only screen and (max-width: 768px) {
  .column p{text-align: left;}
  p.tags{text-align: left;}
  .tags br{display: none;}
  .tags span{ display: unset; }
  .flex{ flex-wrap: wrap;}
  .qoute .flex{ justify-content: center;}
  .pageContent {
    padding: 20px 15px;
}

section.mypick2 {
  height: 77px;
  padding-bottom: 18px;
}
section.mypick2 .flex{flex-wrap: unset;}
section.mypick2 .flex img {
  margin-bottom: 0;
}
section.mypick2 .flex .pdl{padding-left: 20px !important;}
.qoute.mypick2 .quotes{    font-size: 13px; line-height: 1.5; text-align-last: center !important;}

  .pagebanner {
    padding-top: 65px;}

  .navebar li{padding:5px 0px;}

  .aboutHome .contents p {
    margin-top: 0;
}
footer .flex .text-right{text-align: center; }
footer .flex div{width: 100%;}
footer .flex div p{text-align: center;}

.aboutHome.aic{align-items:flex-start;}
  .banner .container {

justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px; 
}
.imtab {
  margin-left: 5px;
  margin-right: 5px;
}
.explore a img {
  width: 150px;
}
.explore .heading {
    display: none;
}
  .copyright {
    text-align: left;
}
  footer h3 {
    margin-bottom: 10px;
    margin-top: 25px;
}

  .container {
    width: calc(100% - 0px);
  }

  .navebar a {
    letter-spacing: inherit;
    font-size: 12px;
    padding: 0px 5px;
  }

  .headerRow {
    height: 50px;
  }

  h1 {
    font-size: 24px;
  }


  p {
    font-size: 14px;
  }

  footer .logo {
    height: 28px;
    margin-bottom: 13px;
    width: auto;
  }
.pdl, .pdr{padding: 0px !important;}

.list li::before, .list li::after{
  background-color: #0a6f43;
}
.list li::marker{  color: #0a6f4300;}
.banner .container {
  padding-bottom: 100px;
}
.BannerTwo .bannercon {
  padding-top: 50px;
}
.bannercon h1{ font-size: 20px !important;}
.bannercon h2{ font-size: 18px !important;}
.bannercon h3{font-size: 16px !important;}
.bannercon p{font-size: 14px !important;}
.container {
  width: calc(100% -  0px) !important;
}
.hasTag p{ text-align: left;}

.coachingProgramBP p:nth-child(1){margin-bottom: 20px; }
input[type=submit].link{ font-size: 20px; border-radius: 100px; padding: 22px 40px;}
}

@media (max-width: 575.98px){
  section, footer{padding:35px 0px;}
  
  .WhyMe p{ padding: 5px 0px;}
  .img50-50{display: flex; flex-wrap: wrap;}
  .img50-50 img{margin: 5px;}
  .img50-50 img:nth-child(1), .img50-50 img:nth-child(2){  max-width:calc(50% - 10px) ; min-width: unset !important  ;}
  .imgHeightControl{width: 100%;}
  .imgHeightControl img{width: 100%; height: 200px; object-fit: cover; object-position: top;}
  .mobimg{ display: block !important;}
  .deskimg{ display: none !important;}
  .mobreqest .quotes{font-size: 12px; text-transform: capitalize;}
section.qoute{ padding: 35px 0px; }

  .carousel .control-next.control-arrow {
    right: 22px !important;}
    .carousel .control-prev.control-arrow {
      left: 22px !important;
      right:unset !important;
  }
  .rowimg img{height: 120px;}
  .carousel.carousel-slider .control-arrow{bottom: 22px !important;}

  .link {
    padding: 16px 19px;
    line-height: 1.3;
    font-size: 12px;
    letter-spacing: unset;
}
  section.mypick2 {
    height:160px;
    padding-bottom: 18px;
    margin: 20px 0px;
}
.qoute.mypick2 .flex {
  flex-wrap: wrap !important;
}
.qoute.mypick2 .flex {
  flex-wrap: wrap !important;
}
.qoute.mypick2 .flex .pdl{padding-left: 0px !important;}
  .wtBg {
    padding: 25px 25px;
}
  .brand img{height: 40px;}
  .thumbnails.details {
    padding: 20px;
}
.smallthumbnails {
  padding: 15px 20px;}
.cotegory{padding:20px 0px}
  .aboutHome .contents p {
    margin-top: 10px;
}
.explore .link {
  line-height: 44px;
  padding: 0px 10px;
  font-size: 12px;
}
.explore .container {
  justify-content: center;
}
.pagebanner .container{padding: 20px 15px;}

.pagebanner .container h1{font-size: 18px;
  line-height: 1.2;}

.iheading {
  text-align: center;
  padding: 40px 0px;
}

}